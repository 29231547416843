import styled from '@emotion/styled';
import { COURSE_TYPES } from '@utils/constants';

export const Wrapper = styled.section({
  width: '100%',
});

export const ContentWrapper = styled.div({
  margin: '0 9.5%',
  textAlign: 'center',
});

export const TextContainer = styled.div({
  maxWidth: 730,
  margin: '0 auto',
  '& h2': {
    lineHeight: '63px',
    marginBottom: 9,
  },
  '& p': {
    fontSize: 21,
    lineHeight: '26px',
    marginBottom: 35,
  },
  '@media (max-width: 480px)': {
    maxWidth: 'none',
    '& h2': {
      width: '106%',
      marginLeft: '-3%',
      letterSpacing: '-1.5px',
    },
    '& p': {
      fontSize: 15,
      lineHeight: '19px',
      marginBottom: 45,
    },
  },
},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textDarkGray,
  },
  '& p': {
    color: theme.colors.textGray,
  },
}));

export const PathsContainer = styled.div({
  display: 'grid',
  gridGap: 20,
  gridTemplateColumns: '1fr 1fr 1fr',
  alignItems: 'stretch',
  '@media (max-width: 480px)': {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const Path = styled.div({
  padding: '0 35px',
  display: 'grid',
  gridTemplateRows: '140px auto 66px',
  justifyItems: 'center',
  borderRadius: 5,
  '& img': {
    opacity: 0.8,
  },
  '& h5': {
    marginBottom: 8,
  },
  '& p': {
    fontSize: 16,
    marginBottom: 24,
  },
  '&:hover': {
    '& img': {
      opacity: 1,
    },
  },
  '@media (max-width: 480px)': {
    width: 236,
    margin: '0 auto 28px auto',
    gridTemplateRows: '90px auto 42px',
    '& h5': {
      marginBottom: 3,
    },
    '& p': {
      fontSize: 12,
      marginBottom: 10,
    },
    '& button': {
      width: 130,
      height: 32,
      fontSize: 12,
    },
    '& img': {
      opacity: 1,
    },
  },
},
({ theme, type }) => ({
  backgroundColor: theme.colors.background,
  boxShadow: theme.shadows.pathItem,
  '&:hover': {
    boxShadow: theme.shadows.pathItemHovered,
  },
  '& h5': {
    color: theme.colors.textHighlighted,
  },
  '& p': {
    color: theme.colors.textGray,
  },
  '@media (max-width: 480px)': {
    order: type === COURSE_TYPES.NOVOTALK ? -1 : 'initial',
  },
}));

export const StyledImage = styled.img({
  alignSelf: 'end',
  marginBottom: 14,
},
({ type }) => ({
  '@media (max-width: 480px)': {
    height: type === COURSE_TYPES.JUNIOR ? 40 : 60,
  },
}));
