import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import SEO from '@components/seo';
import MainLayout from '@layouts/mainLayout';
import MethodologySection from '@components/pages/methodology/methodology';
import PathsSection from '@components/pages/methodology/paths';
import GetStarted from '@components/getStarted';

class MethodologyPage extends Component {
  getSectionContent = (section) => {
    const { data } = this.props;
    return data[section === 'paths' ? 'cockpitshared' : 'cockpitmethodology'][section].childMarkdownRemark.html;
  }

  render() {
    return (
      <MainLayout>
        {/* TODO: add keywords */}
        <SEO title="Methodology" keywords={[]} />
        <MethodologySection content={this.getSectionContent('methodology')} />
        <GetStarted margin={0} />
        <PathsSection content={this.getSectionContent('paths')} />
      </MainLayout>
    );
  }
}

MethodologyPage.propTypes = {
  data: PropTypes.object,
};

export default MethodologyPage;

export const query = graphql`
  query ApproachQuery {
    cockpitmethodology {
      methodology: childCockpitmethodologyMethodologyTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
    cockpitshared {
      paths: childCockpitsharedPathsTextNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`;
