import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';

import Button from '@components/button';
import RefresherSVG from '@assets/images/paths_refresher_home.svg';
import JuniorSVG from '@assets/images/paths_junior_home.svg';
import ChronicSVG from '@assets/images/paths_chronic_home.svg';
import {
  Wrapper, ContentWrapper, TextContainer, PathsContainer, Path, StyledImage,
} from './styles';

const renderPath = (img, content, path) => {
  if (path !== 'Novotalk Flow') return <div />;
  return (
    <Path type={path}>
      <StyledImage src={img} type={path} />
      <div dangerouslySetInnerHTML={{ __html: content }} />
      <Button
        onClick={() => navigate(`/programs/${path}`)}
        width={220}
        height={43}
        outline
      >
      Tell me more
      </Button>
    </Path>
  );
};

const PathsSection = ({ content }) => {
  const splittedContent = content.split('<hr>');
  return (
    <Wrapper>
      <ContentWrapper>
        <TextContainer dangerouslySetInnerHTML={{ __html: splittedContent[0] }} />
        <PathsContainer>
          {renderPath(JuniorSVG, splittedContent[1], 'Novotalk Flow Jr.')}
          {renderPath(ChronicSVG, splittedContent[2], 'Novotalk Flow')}
          {renderPath(RefresherSVG, splittedContent[3], 'Accelerator')}
        </PathsContainer>
      </ContentWrapper>
    </Wrapper>
  );
};

PathsSection.propTypes = {
  content: PropTypes.string,
};

export default PathsSection;
