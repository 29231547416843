import React from 'react';
import styled from '@emotion/styled';

import PathsSection from '@components/paths';

const Wrapper = styled.div({
  marginTop: 83,
  marginBottom: 55,
  '@media (max-width: 480px)': {
    marginTop: 100,
    marginBottom: 50,
  },
},
({ theme }) => ({
  '& section h2': {
    color: theme.colors.textHighlighted,
  },
}));

export default (props) => (
  <Wrapper>
    <PathsSection {...props} />
  </Wrapper>
);
