import styled from '@emotion/styled';

export const Wrapper = styled.section({
  width: '100%',
  marginBottom: 150,
  padding: '128px 10% 0 10%',
  '@media (max-width: 480px)': {
    paddingTop: 52,
    marginBottom: 100,
  },
});

export const ContentWrapper = styled.div({
  width: '80%',
  '& h2': {
    marginBottom: 25,
  },
  '& p': {
    fontSize: 18,
    marginBottom: 40,
    lineHeight: '26px',
  },
  '@media (max-width: 480px)': {
    width: '100%',
    '& h2': {
      textAlign: 'center',
      marginBottom: 60,
    },
    '& p, & p:first-of-type': {
      fontSize: 15,
      marginBottom: 20,
      lineHeight: '19px',
    },
  },

},
({ theme }) => ({
  '& h2': {
    color: theme.colors.textHighlighted,
  },
  '& h4': {
    color: theme.colors.textGray,
  },
  '& li': {
    color: theme.colors.textGray,
    '&:before': {
      backgroundColor: theme.colors.buttonPrimary,
    },
  },
}));
